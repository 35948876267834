import Router from "next/router";

export default (target: string, res?: any) => {
  if (res) {
    // server
    // 303: "See other"

    // console.log("Browser redirect");
    res.writeHead(303, { Location: target });
    res.end();

    return;
  }

  // if (router) {
  //   // In the browser, we just pretend like this never even happened ;)

  //   // console.log("Server redirect");
  //   // console.log(target);
  //   router.replace(target, undefined, { shallow: true });
  // }

  // In the browser, we just pretend like this never even happened ;)
  Router.push(target, undefined, { shallow: true });
};
