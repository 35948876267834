export const log = async (...message: any) => {
  process.env.NODE_ENV === "development"
    ? console.log(...message)
    : await fetch(process.env.NEXT_PUBLIC_BASE_URL + `/api/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content:
            `<h1>Error on: ${
              process.env.NEXT_PUBLIC_WEBSITE_NAME || "the NextJS system"
            }</h1>` +
            `<h5>The error message as below: </h5>` +
            `<p>${message}</p>`,
          subject:
            "Error on " +
            (process.env.NEXT_PUBLIC_WEBSITE_NAME || "the NextJS system"),
        }),
      });

  // console.log(...message);
};
