import React from "react";
// import cookie from "cookie";
import PropTypes from "prop-types";
// import Head from "next/head";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { getDataFromTree } from "@apollo/client/react/ssr";

import initApollo from "./initApollo";
import redirect from "./redirect";
import { isBrowser } from "./isBrowser";
import { log } from "./log";
// import { GetStaticPropsContext } from "next";

// function parseCookies(req?: any, options = {}) {
//   // return cookie.parse(
//   //   req ? req.headers.cookie || "" : document ? document.cookie : "",
//   //   options
//   // );

//   return cookie.parse(
//     req ? req.headers.cookie || "" : document.cookie,
//     options
//   );
// }
//

const App = (App: any) => {
  return class WithData extends React.Component {
    static displayName = `WithData(${App.displayName})`;
    static propTypes = {
      apolloState: PropTypes.object.isRequired,
    };

    static async getInitialProps(ctx: any) {
      const {
        Component,
        router,
        // ctx: { req, res },
        ctx: { res },
      } = ctx;
      const apollo = initApollo(
        {},
        {
          // getToken: () => parseCookies(req).qid,
        }
      );

      // log(req);

      ctx.ctx.apolloClient = apollo;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      if (!isBrowser) {
        // Run all graphql queries in the component tree
        // and extract the resulting data
        try {
          // console.log("SSR");

          // Run all GraphQL queries
          await getDataFromTree(
            <App
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />
          );
        } catch (error: any) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error

          // console.error("Error while running `getDataFromTree`", error);
          // console.log(error);
          log("Error while running `getDataFromTree`" + error);

          if (error.message.includes("Permission denied")) {
            const path = decodeURIComponent(ctx.ctx.asPath).replace(
              /\/login\?from\=|\/login/gi,
              ""
            );

            redirect(
              path ? `/login?from=${encodeURIComponent(path)}` : "/login",
              ctx.ctx.res
            );
          }
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        // Head.rewind();
      }

      // Extract query data from the Apollo's store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
      };
    }

    apolloClient: ApolloClient<NormalizedCacheObject>;

    constructor(props: any) {
      super(props);
      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient = initApollo(props.apolloState, {
        // getToken: () => {
        //   return parseCookies().token;
        // },
      });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};

export default App;
