import * as React from "react";
// import Link from 'next/link'
import Head from "next/head";

// import '../../../styles/scss/index.scss';
import theme from "../theme";

type Props = {
  title?: string;
  pathname?: string;
  container?: boolean;
  type?: string;
  desc?: string;
  image?: {
    url: string;
    width: number;
    height: number;
  };
};

const MetaData: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  title = process.env.NEXT_PUBLIC_WEBSITE_NAME,
  pathname = "/",
  type = "website",
  desc = process.env.NEXT_PUBLIC_WEBSITE_NAME,
  image = { url: "/seo.png", width: 1080, height: 1080 },
}) => {
  return (
    <Head>
      <title>{title}</title>

      <link
        rel="shortcut icon"
        href={process.env.NEXT_PUBLIC_WEBSITE_ICO_PATH}
      />

      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      {/* PWA primary color */}
      <meta name="theme-color" content={theme.palette.primary.main} />

      <meta
        name="msapplication-TileColor"
        content={theme.palette.primary.main}
      />
      {/* <meta name="msapplication-TileImage" content="/static/favicon/ms-icon-144x144.png"/> */}

      {/* Facebook's Open Graph Markup */}
      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_BASE_URL + (pathname ? pathname : "")}
      />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      {/* <meta property="og:image"              content={`${host}/${image.url}`} />
			<meta property="og:image:width" content={`${image.width}`} />
			<meta property="og:image:height" content={`${image.height}`} /> */}

      {/* Twitter's Markup */}
      <meta
        name="twitter:card"
        content={`${process.env.NEXT_PUBLIC_BASE_URL}/${image.url}`}
      />

      {/* <link rel="apple-touch-icon" sizes="57x57" href="/static/favicon/apple-icon-57x57.png"/>
			<link rel="apple-touch-icon" sizes="60x60" href="/static/favicon/apple-icon-60x60.png"/>
			<link rel="apple-touch-icon" sizes="72x72" href="/static/favicon/apple-icon-72x72.png"/>
			<link rel="apple-touch-icon" sizes="76x76" href="/static/favicon/apple-icon-76x76.png"/>
			<link rel="apple-touch-icon" sizes="114x114" href="/static/favicon/apple-icon-114x114.png"/>
			<link rel="apple-touch-icon" sizes="120x120" href="/static/favicon/apple-icon-120x120.png"/>
			<link rel="apple-touch-icon" sizes="144x144" href="/static/favicon/apple-icon-144x144.png"/>
			<link rel="apple-touch-icon" sizes="152x152" href="/static/favicon/apple-icon-152x152.png"/>
			<link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-icon-180x180.png"/>
			<link rel="icon" type="image/png" sizes="192x192"  href="/static/favicon/android-icon-192x192.png"/>
			<link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png"/>
			<link rel="icon" type="image/png" sizes="96x96" href="/static/favicon/favicon-96x96.png"/>
			<link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png"/>
			<link rel="manifest" href="/static/favicon/manifest.json"/> */}
    </Head>
  );
};

export default MetaData;
