import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-unfetch";
import Router from "next/router";
import { log } from "./log";
import { isBrowser } from "./isBrowser";
import moment from "moment";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!isBrowser) {
  (global as any).fetch = fetch;
}

interface Options {
  // getToken(): string;
  fetchOptions?: any;
}

// function create(initialState: any, { getToken, fetchOptions }: Options) {
function create(initialState: any, { fetchOptions }: Options) {
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    credentials: "include",
    fetchOptions,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      let errMsg = "";

      graphQLErrors.map(({ message, locations, path }) => {
        if (!message.includes("Permission denied")) {
          errMsg +=
            process.env.NODE_ENV === "production"
              ? `[GraphQL error]: Message: ${message}, <br/>Location: ${
                  locations ? locations.join("<br/>") : "undefined"
                }, ${
                  process.env.NODE_ENV === "production" && "<br/>"
                }Path: ${path} <br/>`
              : `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} <br/>`;
        }
        // log(message.includes('Permission denied'), 'web')

        if (isBrowser && message.includes("Permission denied")) {
          const path = decodeURIComponent(Router.asPath).replace(
            /\/login\?from\=|\/login/gi,
            ""
          );

          Router.replace(
            path ? `/login?from=${encodeURIComponent(path)}` : "/login"
          );
        }
      });

      // Log if error message exist
      errMsg && log(errMsg);
    }

    if (networkError) {
      log(`[Network error]: ${networkError}`);

      if (isBrowser && networkError.message.includes("connect ECONNREFUSED")) {
        Router.replace("/error/network");
      }
    }
  });

  const authLink = setContext((_, { headers }) => {
    // const token = getToken();
    return {
      headers: {
        ...headers,
        // cookie: token ? `qid=${token}` : "",
        "x-tz-offset": moment().utcOffset(),
        "x-forwarded-proto": "https",
      },
    };
  });

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        UserProfile: {
          merge: true,
        },
      },
    }).restore(initialState || {}),
  });
}

export default function initApollo(initialState: any, options: Options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser) {
    let fetchOptions = {};
    // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
    // 'https-proxy-agent' is required here because it's a sever-side only module
    // if (process.env.https_proxy) {
    //   fetchOptions = {
    //     agent: new (require('https-proxy-agent'))(process.env.https_proxy)
    //   };
    // }
    return create(initialState, {
      ...options,
      fetchOptions,
    });
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
