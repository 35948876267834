import App from "next/app";
import React from "react";
import { ApolloProvider } from "@apollo/client";

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../src/theme";

import withApollo from "../lib/withApollo";
import MetaData from "../src/components/MetaData";
// import { Html } from "next/document";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

class MyApp extends App<any> {
  render() {
    const { Component, pageProps, apolloClient, router } = this.props;
    const Layout = Component.Layout || null;
    const layoutProps = Component.layoutProps || null;
    const metaProps = Component.metaProps || null;

    return (
      <ApolloProvider client={apolloClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.*/}
            <CssBaseline />
            <MetaData pathname={router.asPath} {...metaProps} />
            {Layout ? (
              <Layout {...layoutProps}>
                <Component {...pageProps} />
              </Layout>
            ) : (
              <Component {...pageProps} />
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    );
  }
}

export default withApollo(MyApp);
